import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { useForm } from "../components/hooks/useForm";
import Layout from "../components/layout";
import Head from "../components/head"

import emailIcon from '../components/icons/envelope-square-solid.svg';
//import phoneIcon from '../components/icons/phone.png';
import locationIcon from '../components/icons/location.png';

const ContactPage = () => {
    const [values, handleChange, resetValues] = useForm({ name: '', email: '', subject: '', message: '' });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const refForm = useRef();

    const setMessages = (message = '', setMessage) => {
        setMessage(message);
        setTimeout(() => {
            setMessage('');
        }, 5000)
    };

    const onSendEmail = async (e) => {
        e.preventDefault();

        const validEmail = refForm.current.name.value && refForm.current.email.value && refForm.current.message.value;

        if (!validEmail) {
            setMessages('Make sure you set all fields', setError);
            return;
        }
        
        const res = await emailjs.sendForm(`${process.env.GATSBY_SERVICE_ID}`, `${process.env.GATSBY_TEMPLATE_ID}`, refForm.current, `${process.env.GATSBY_USER_ID}`);

        if (res.text !== 'OK') {
            setMessages('Server error!', setError);
            return;
        }

        setMessages('Send successfully', setSuccessMessage);

        resetValues();
    };

    return (
        <>
            {/**<GatsbySeo
                description="Contact me, a Jr. Full Stack Web Developer with skills in ReactJS, Django, and Tailwind, to discuss your web development needs. Let's work together to build dynamic, responsive, and user-friendly web applications."
            /> */}
            <Layout>
                <Head
                    title="Contact"
                    description="Let's work together, contact me to discuss your project's idea."
                />
                <div className="contact-me">
                    <h1>Let's work together</h1>
                    <div className="contact-me__body">
                        <div className="contact-me__infos">
                            <div>
                                <img className="contact-me__icon" src={locationIcon} alt='location icon' />
                                <h5>
                                    {process.env.GATSBY_LOCATION}
                                </h5>
                            </div>
                            {/*
                            <div>
                                <img className="contact-me__icon" src={phoneIcon} alt='phone icon' />
                                <h5>
                                    {process.env.GATSBY_PHONE}
                                </h5>
                            </div>
                            */}
                            <div>
                                <img className="contact-me__icon" src={emailIcon} alt='email icon' />
                                <h5>
                                    <a href={`mailto: ${process.env.GATSBY_EMAIL}`}>{process.env.GATSBY_EMAIL}</a>
                                </h5>
                            </div>
                        </div>
                        <form ref={refForm} onSubmit={onSendEmail}>
                            <div className="form-messages">
                                <p className="form-message__error">{error}</p>
                                <p className="form-message__success">{successMessage}</p>
                            </div>
                            <input
                                name="name"
                                placeholder="Name"
                                type='text'
                                value={values.name}
                                onChange={handleChange}
                            />
                            <input
                                name="email"
                                placeholder="Email"
                                type='email'
                                value={values.email}
                                onChange={handleChange}
                            />
                            <input
                                name="subject"
                                placeholder="Subject"
                                type='text'
                                value={values.subject}
                                onChange={handleChange}
                            />
                            <textarea
                                name="message"
                                placeholder="Your message"
                                value={values.message}
                                onChange={handleChange}
                            />
                            <div className="contact-me__button-container">
                                <button>Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default ContactPage;